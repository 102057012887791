<template>
  <div v-if="user">
    <v-avatar
      color="primary"
      size="30"
      :title="name"
    >
      <span class="overline white--text">{{ initials }}</span>
    </v-avatar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CurrentUser',
  computed: {
    ...mapGetters('auth', ['name', 'user']),
    initials () {
      return this.name.split(' ').map((part) => part.charAt(0)).join('')
    },
  },
}
</script>
