<template>
  <div class="my-5">
    <v-pagination
      v-if="lastPage > 1"
      v-model="currentPage"
      :length="lastPage"
      :disabled="loading"
      circle
      class="mb-3"
      @input="onPageChange"
    />
    <div
      v-if="total > 0"
      class="text-center body-2"
    >
      <v-skeleton-loader
        v-if="loading"
        class="d-flex align-center mx-auto"
        max-width="140"
        height="20"
        type="text"
      />
      <span v-else>{{ $t('components.pagination.info', { from, to, total }) }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ListPagination',
  computed: {
    ...mapGetters('app', [
      'lastPage',
      'page',
      'total',
      'from',
      'to',
      'loading',
    ]),
    currentPage: {
      get () {
        return this.page
      },
      set (page) {
        this.setPage(page)
      },
    },
  },
  methods: {
    ...mapActions('app', ['getReleases']),
    ...mapMutations('app', ['setPage']),
    onPageChange () {
      this.getReleases()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-skeleton-loader__text {
    margin-bottom: 0;
  }
}
</style>
