<template>
  <v-app>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <Snackbar />
  </v-app>
</template>
<script>
import Snackbar from '@/components/Snackbar'

export default {
  components: { Snackbar },
}
</script>
