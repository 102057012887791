<template>
  <v-app-bar
    dense
  >
    <v-toolbar-title>{{ $t('home.pageTitle') }}</v-toolbar-title>

    <v-spacer />

    <DarkModeSwitch class="mr-4" />
    <CurrentUser />
    <LogoutButton />
  </v-app-bar>
</template>

<script>
import LogoutButton from '@/components/LogoutButton';
import CurrentUser from '@/components/CurrentUser';
import DarkModeSwitch from '@/components/DarkModeSwitch';

export default {
  name: 'AppBar',
  components: {
    DarkModeSwitch,
    LogoutButton,
    CurrentUser,
  },
}
</script>
