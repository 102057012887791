<template>
  <v-btn
    icon
    :loading="loading"
    :title="$t('components.logoutButton.logout')"
    @click="onClick"
  >
    <v-icon small>
      $vuetify.icons.signOutAlt
    </v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LogoutButton',
  computed: {
    ...mapGetters('auth', ['loading']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async onClick () {
      await this.logout()
    },
  },
}
</script>
