import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/lib/locale/de' // Load translation for vuetify labels
import { icons } from './fontawesome'
import { isDarkModeEnabled, isDarkModeEnabledMq } from "@/plugins/prefersColorScheme";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons,
  lang: {
    locales: { de },
    current: 'de',
  },
  theme: {
    dark: isDarkModeEnabled,
  },
});

isDarkModeEnabledMq.addEventListener('change', (e) => {
  vuetify.framework.theme.dark = e.matches
})

export default vuetify
