import ReleaseList from '@/views/ReleaseList';

export const defaultRouteName = 'release-list';

export default [
  {
    path: '/',
    name: 'release-list',
    component: ReleaseList,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
]
