<template>
  <v-text-field
    :value="q"
    :label="$t('components.listSearch.label')"
    solo
    hide-details
    clearable
    @input="debounceSearch"
  />
</template>

<script>
import debounce from 'lodash.debounce'
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ListSearch',
  computed: {
    ...mapGetters('app', ['q']),
  },
  methods: {
    ...mapMutations('app', ['setQuery']),
    debounceSearch: debounce(function (value) {
      this.setQuery(value)
      this.$emit('search')
    }, 300),
  },
}
</script>
