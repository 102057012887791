<template>
  <v-dialog
    v-model="editDialog"
    persistent
    max-width="600px"
    @keydown.esc="onCloseClick"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="isCreate"
        color="green"
        fab
        fixed
        bottom
        right
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          $vuetify.icons.plus
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          $vuetify.icons.edit
        </v-icon>
      </v-btn>
    </template>
    <v-form
      ref="releaseForm"
      v-model="valid"
      @submit.prevent="onFormSubmit"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            isCreate ?
              $t('components.editDialog.titleCreate', { releaseName: releaseToEdit.name || defaultReleaseName }) :
              $t('components.editDialog.titleEdit', { releaseName: release.name })
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="releaseToEdit.name"
                :label="$t('components.editDialog.form.name')"
                :rules="nameRules"
                :disabled="disabled"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-if="filters.languages"
                v-model="releaseToEdit.language_id"
                :items="filters.languages.options"
                item-value="id"
                item-text="name"
                :label="$t('components.editDialog.form.languages')"
                :disabled="disabled"
                required
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
            >
              <v-text-field
                v-model="releaseToEdit.hd"
                :label="$t('components.editDialog.form.hd')"
                :disabled="disabled"
                :rules="hdRules"
                required
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
            >
              <v-menu
                v-model="createdAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedCreatedAt"
                    :label="$t('components.editDialog.form.createdAt')"
                    :disabled="disabled"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="vuetifyFormattedCreatedAt"
                  @input="onCreatedAtChange"
                />
              </v-menu>
            </v-col>
          </v-row>
          <template v-if="isMovieTypeSelected || isSeriesTypeSelected">
            <v-row dense>
              <v-col
                cols="6"
              >
                <v-select
                  v-if="filters.videoSources"
                  v-model="releaseToEdit.video_source_id"
                  :items="filters.videoSources.options"
                  item-value="id"
                  item-text="name"
                  :label="$t('components.editDialog.form.videoSources')"
                  :disabled="disabled"
                  required
                  @change="onVideoSourceChange"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-if="filters.videoFormats"
                  v-model="releaseToEdit.video_format_id"
                  :items="filters.videoFormats.options"
                  item-value="id"
                  item-text="name"
                  :label="$t('components.editDialog.form.videoFormats')"
                  :disabled="disabled"
                  required
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-if="filters.videoResolutions"
                  v-model="releaseToEdit.video_resolution_id"
                  :items="filters.videoResolutions.options"
                  item-value="id"
                  item-text="name"
                  :label="$t('components.editDialog.form.videoResolutions')"
                  :disabled="disabled"
                  required
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-if="audioSources"
                  v-model="releaseToEdit.audio_source_id"
                  :items="audioSources"
                  item-value="id"
                  item-text="name"
                  :label="$t('components.editDialog.form.audioSources')"
                  :disabled="disabled"
                  required
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-if="filters.audioFormats"
                  v-model="releaseToEdit.audio_format_id"
                  :items="filters.audioFormats.options"
                  item-value="id"
                  item-text="name"
                  :label="$t('components.editDialog.form.audioFormats')"
                  :disabled="disabled"
                  required
                  @change="onAudioFormatChange"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-if="filters.audioChannels"
                  v-model="releaseToEdit.audio_channel_id"
                  :items="filters.audioChannels.options"
                  item-value="id"
                  item-text="name"
                  :label="$t('components.editDialog.form.audioChannels')"
                  :disabled="disabled"
                  required
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="6"
                sm="4"
              >
                <v-checkbox
                  v-model="releaseToEdit.has_atmos"
                  :label="$t('components.editDialog.form.hasAtmos')"
                  :disabled="disabled"
                  hide-details
                />
              </v-col>
              <v-col
                cols="6"
                sm="4"
              >
                <v-checkbox
                  v-model="releaseToEdit.has_dtsx"
                  :label="$t('components.editDialog.form.hasDtsx')"
                  :disabled="disabled"
                  hide-details
                />
              </v-col>
              <v-col
                cols="6"
                sm="4"
              >
                <v-checkbox
                  v-model="releaseToEdit.has_dolby_vision"
                  :label="$t('components.editDialog.form.hasDolbyVision')"
                  :disabled="disabled"
                  hide-details
                />
              </v-col>
              <v-col
                cols="6"
                sm="4"
              >
                <v-checkbox
                  v-model="releaseToEdit.has_hdr"
                  :label="$t('components.editDialog.form.hasHdr')"
                  :disabled="disabled"
                  hide-details
                />
              </v-col>
              <v-col
                cols="6"
                sm="4"
              >
                <v-checkbox
                  v-model="releaseToEdit.has_hdr_10_plus"
                  :label="$t('components.editDialog.form.hasHdr10Plus')"
                  :disabled="disabled"
                  hide-details
                />
              </v-col>
              <v-col
                cols="6"
                sm="4"
              >
                <v-checkbox
                  v-model="releaseToEdit.has_hfr"
                  :label="$t('components.editDialog.form.hasHfr')"
                  :disabled="disabled"
                  hide-details
                />
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="saving"
            :disabled="!valid || disabled"
            type="submit"
          >
            {{ $t('components.editDialog.save') }}
          </v-btn>
          <v-btn
            text
            :disabled="saving || deleting"
            @click="onCloseClick"
          >
            {{ $t('components.editDialog.cancel') }}
          </v-btn>
          <v-spacer />
          <v-dialog
            v-if="!isCreate"
            v-model="deleteDialog"
            persistent
            max-width="290"
            @keydown.esc="onDeleteCancelClick"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="red"
                icon
                :loading="deleting"
                :disabled="disabled"
                :title="$t('components.editDialog.delete')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  $vuetify.icons.trash
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                {{ $t('components.editDialog.deleteDialog.title') }}
              </v-card-title>
              <v-card-text>
                {{ $t('components.editDialog.deleteDialog.description', { releaseName: release.name }) }}
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red"
                  @click="onDeleteConfirmClick"
                >
                  {{ $t('components.editDialog.deleteDialog.confirm') }}
                </v-btn>
                <v-btn
                  text
                  @click="onDeleteCancelClick"
                >
                  {{ $t('components.editDialog.deleteDialog.cancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  BLURAY_VIDEO_SOURCE_ID,
  BLURAY_3D_VIDEO_SOURCE_ID,
  movieDefaultValues,
  UHD_BLURAY_VIDEO_SOURCE_ID,
  MP3_AUDIO_FORMAT_ID,
  AUDIO_CHANNEL_20_ID,
  X265_VIDEO_FORMAT_ID,
  X264_VIDEO_FORMAT_ID,
  VIDEO_RESOLUTION_1080P_ID,
  VIDEO_RESOLUTION_2160P_ID,
} from '@/utils/movie';
import {
  seriesDefaultValues,
} from '@/utils/series';
import {
  gameDefaultValues,
} from '@/utils/game';
import {
  applicationDefaultValues,
} from '@/utils/application';
import {
  formatISODate,
  formatISODateForVuetify,
  getToday,
} from '@/utils/date';

export default {
  name: 'EditDialog',
  props: {
    release: {
      type: Object,
      default () {
        if (this.isMovieTypeSelected) {
          return movieDefaultValues
        } else if (this.isGameTypeSelected) {
          return gameDefaultValues
        } else if (this.isApplicationTypeSelected) {
          return applicationDefaultValues
        } else if (this.isSeriesTypeSelected) {
          return seriesDefaultValues
        }
        return {}
      },
    },
    isCreate: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      editDialog: false,
      deleteDialog: false,
      releaseToEdit: {},
      saving: false,
      deleting: false,
      valid: true,
      createdAtMenu: false,
      nameRules: [
        v => {
          return !!v || this.$t('components.editDialog.rules.name')
        },
      ],
      hdRules: [
        v => {
          return !!v || this.$t('components.editDialog.rules.hd')
        },
      ],
    }
  },
  computed: {
    ...mapGetters('app', [
      'filters',
      'isMovieTypeSelected',
      'isGameTypeSelected',
      'isApplicationTypeSelected',
      'isSeriesTypeSelected',
    ]),
    disabled () {
      return this.saving || this.deleting
    },
    audioSources () {
      if (this.filters.audioSources && this.filters.audioSources.options) {
        // Add empty option to audio sources
        return [
          {
            id: 0,
            name: this.$t('components.editDialog.form.audioSourcesEmptyOption'),
          },
          ...this.filters.audioSources.options,
        ]
      }
      return []
    },
    defaultReleaseName () {
      if (this.isMovieTypeSelected) {
        return this.$t('components.editDialog.defaultMovieName')
      } else if (this.isGameTypeSelected) {
        return this.$t('components.editDialog.defaultGameName')
      } else if (this.isApplicationTypeSelected) {
        return this.$t('components.editDialog.defaultApplicationName')
      } else if (this.isSeriesTypeSelected) {
        return this.$t('components.editDialog.defaultSeriesName')
      }
      return ''
    },
    vuetifyFormattedCreatedAt: {
      get () {
        return formatISODateForVuetify(this.releaseToEdit.created_at)
      },
      set (createdAt) {
        this.releaseToEdit.created_at = createdAt
      },
    },
    formattedCreatedAt () {
      return formatISODate(this.releaseToEdit.created_at)
    },
  },
  watch: {
    editDialog(open) {
      if (open) {
        this.resetDialog()
      }
    },
  },
  mounted () {
    this.releaseToEdit = this.getLocalReleaseCopy()
  },
  methods: {
    ...mapActions('app', ['updateRelease', 'createRelease', 'deleteRelease']),
    onCloseClick () {
      this.editDialog = false
    },
    onCreatedAtChange () {
      this.createdAtMenu = false
    },
    async onFormSubmit () {
      this.saving = true
      if (this.isCreate) {
        await this.createRelease(this.releaseToEdit)
      } else {
        await this.updateRelease(this.releaseToEdit)
      }
      this.$emit('saved')
      this.saving = false
      this.editDialog = false
    },
    async onDeleteConfirmClick () {
      this.deleteDialog = false
      this.deleting = true
      await this.deleteRelease(this.releaseToEdit)
      this.$emit('deleted')
      this.deleting = false
      this.editDialog = false
    },
    onDeleteCancelClick () {
      this.deleteDialog = false
    },
    onVideoSourceChange () {
      if (this.isCreate && (this.isMovieTypeSelected || this.isSeriesTypeSelected)) {
        if (this.releaseToEdit.video_source_id === UHD_BLURAY_VIDEO_SOURCE_ID) {
          this.releaseToEdit.video_resolution_id = VIDEO_RESOLUTION_2160P_ID;
          this.releaseToEdit.video_format_id = X265_VIDEO_FORMAT_ID;
          this.releaseToEdit.has_hdr = true;
        } else if (this.releaseToEdit.video_source_id === BLURAY_VIDEO_SOURCE_ID || this.releaseToEdit.video_source_id === BLURAY_3D_VIDEO_SOURCE_ID) {
          this.releaseToEdit.video_resolution_id = VIDEO_RESOLUTION_1080P_ID;
          this.releaseToEdit.video_format_id = X264_VIDEO_FORMAT_ID;
          this.releaseToEdit.has_hdr = false;
        }
      }
    },
    onAudioFormatChange () {
      if (this.isCreate && (this.isMovieTypeSelected || this.isSeriesTypeSelected)) {
        if (this.releaseToEdit.audio_format_id === MP3_AUDIO_FORMAT_ID) {
          this.releaseToEdit.audio_channel_id = AUDIO_CHANNEL_20_ID;
        }
      }
    },
    getLocalReleaseCopy () {
      if (this.isMovieTypeSelected) {
        return this.getLocalMovieCopy()
      } else if (this.isGameTypeSelected) {
        return this.getLocalGameCopy()
      } else if (this.isApplicationTypeSelected) {
        return this.getLocalApplicationCopy()
      } else if (this.isSeriesTypeSelected) {
        return this.getLocalSeriesCopy()
      }
    },
    getLocalMovieCopy () {
      const {
        id,
        name,
        language_id,
        audio_format_id,
        audio_channel_id,
        audio_source_id,
        video_format_id,
        video_source_id,
        video_resolution_id,
        has_atmos,
        has_dtsx,
        has_dolby_vision,
        has_hdr,
        has_hdr_10_plus,
        has_hfr,
        hd,
        created_at,
      } = this.release
      return {
        id,
        name,
        language_id: language_id || movieDefaultValues.language_id,
        audio_format_id: audio_format_id || movieDefaultValues.audio_format_id,
        audio_channel_id: audio_channel_id || movieDefaultValues.audio_channel_id,
        audio_source_id: audio_source_id || movieDefaultValues.audio_source_id,
        video_format_id: video_format_id || movieDefaultValues.video_format_id,
        video_source_id: video_source_id || movieDefaultValues.video_source_id,
        video_resolution_id: video_resolution_id || movieDefaultValues.video_resolution_id,
        has_atmos: has_atmos || movieDefaultValues.has_atmos,
        has_dtsx: has_dtsx || movieDefaultValues.has_dtsx,
        has_dolby_vision: has_dolby_vision || movieDefaultValues.has_dolby_vision,
        has_hdr: has_hdr || movieDefaultValues.has_hdr,
        has_hdr_10_plus: has_hdr_10_plus || movieDefaultValues.has_hdr_10_plus,
        has_hfr: has_hfr || movieDefaultValues.has_hfr,
        hd: hd || movieDefaultValues.hd,
        created_at: created_at || getToday(),
      }
    },
    getLocalSeriesCopy () {
      const {
        id,
        name,
        language_id,
        audio_format_id,
        audio_channel_id,
        audio_source_id,
        video_format_id,
        video_source_id,
        video_resolution_id,
        has_atmos,
        has_dtsx,
        has_dolby_vision,
        has_hdr,
        has_hdr_10_plus,
        has_hfr,
        hd,
        created_at,
      } = this.release
      return {
        id,
        name,
        language_id: language_id || seriesDefaultValues.language_id,
        audio_format_id: audio_format_id || seriesDefaultValues.audio_format_id,
        audio_channel_id: audio_channel_id || seriesDefaultValues.audio_channel_id,
        audio_source_id: audio_source_id || seriesDefaultValues.audio_source_id,
        video_format_id: video_format_id || seriesDefaultValues.video_format_id,
        video_source_id: video_source_id || seriesDefaultValues.video_source_id,
        video_resolution_id: video_resolution_id || seriesDefaultValues.video_resolution_id,
        has_atmos: has_atmos || seriesDefaultValues.has_atmos,
        has_dtsx: has_dtsx || seriesDefaultValues.has_dtsx,
        has_dolby_vision: has_dolby_vision || seriesDefaultValues.has_dolby_vision,
        has_hdr: has_hdr || seriesDefaultValues.has_hdr,
        has_hdr_10_plus: has_hdr_10_plus || seriesDefaultValues.has_hdr_10_plus,
        has_hfr: has_hfr || seriesDefaultValues.has_hfr,
        hd: hd || seriesDefaultValues.hd,
        created_at: created_at || getToday(),
      }
    },
    getLocalGameCopy () {
      const {
        id,
        name,
        language_id,
        hd,
        created_at,
      } = this.release
      return {
        id,
        name,
        language_id: language_id || gameDefaultValues.language_id,
        hd: hd || gameDefaultValues.hd,
        created_at: created_at || getToday(),
      }
    },
    getLocalApplicationCopy () {
      const {
        id,
        name,
        language_id,
        hd,
        created_at,
      } = this.release
      return {
        id,
        name,
        language_id: language_id || applicationDefaultValues.language_id,
        hd: hd || applicationDefaultValues.hd,
        created_at: created_at || getToday(),
      }
    },
    resetDialog () {
      this.releaseToEdit = this.getLocalReleaseCopy()
      this.saving = false
      this.deleting = false

      if (this.$refs.releaseForm) {
        this.$refs.releaseForm.resetValidation()
      }
    },
  },
}
</script>
