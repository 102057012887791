<template>
  <div class="release-list">
    <AppBar />
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
      >
        <ListSearch
          @search="onSearch"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
      >
        <ListType
          @type-change="onTypeChange"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
      >
        <ListSort
          @sort-change="onSort"
        />
      </v-col>
    </v-row>
    <ListFilters
      @change="onFilterChange"
      @reset="onFilterReset"
    />
    <v-list
      v-if="!loading && releases.length > 0"
    >
      <template
        v-for="release in releases"
      >
        <MovieItem
          v-if="isMovieTypeSelected || isSeriesTypeSelected"
          :key="release.id"
          :movie="release"
          @changed="getReleases"
        />
        <GameItem
          v-else-if="isGameTypeSelected"
          :key="release.id"
          :game="release"
          @changed="getReleases"
        />
        <ApplicationItem
          v-else-if="isApplicationTypeSelected"
          :key="release.id"
          :application="release"
          @changed="getReleases"
        />
      </template>
    </v-list>
    <v-list v-else-if="loading">
      <ListItemSkeleton
        v-for="i in itemsPerPage"
        :key="i"
      />
    </v-list>
    <div
      v-else
      class="text-center my-5"
    >
      <p>{{ $t('releaseList.noResults') }}</p>
      <v-btn
        @click="onResetSearchClick"
      >
        {{ $t('releaseList.resetSearch') }}
      </v-btn>
    </div>
    <ListPagination />
    <EditDialog
      :is-create="true"
      @saved="onEditSave"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AppBar from '@/components/AppBar';
import MovieItem from '@/components/List/MovieItem';
import GameItem from '@/components/List/GameItem';
import ApplicationItem from '@/components/List/ApplicationItem';
import ListItemSkeleton from '@/components/List/ItemSkeleton';
import ListPagination from '@/components/List/Pagination';
import ListSearch from '@/components/List/Search';
import ListSort from '@/components/List/Sort';
import ListFilters from '@/components/List/Filters';
import EditDialog from '@/components/List/EditDialog';
import ListType from '@/components/List/Type';

export default {
  name: 'ReleaseList',
  components: {
    ApplicationItem,
    GameItem,
    ListType,
    EditDialog,
    ListFilters,
    ListSearch,
    ListSort,
    ListPagination,
    ListItemSkeleton,
    MovieItem,
    AppBar,
  },
  computed: {
    ...mapGetters('app', [
      'releases',
      'itemsPerPage',
      'loading',
      'isMovieTypeSelected',
      'isGameTypeSelected',
      'isApplicationTypeSelected',
      'isSeriesTypeSelected',
    ]),
  },
  mounted () {
    this.getReleases()
    this.getFilters()
  },
  methods: {
    ...mapActions('app', ['getReleases', 'getFilters']),
    ...mapMutations('app', ['resetPage', 'resetSearch', 'resetFilters', 'closeFilterPanels']),
    onSearch () {
      this.resetPage()
      this.getReleases()
    },
    onFilterChange () {
      this.resetPage()
      this.getReleases()
    },
    onFilterReset () {
      this.resetPage()
      this.getReleases()
    },
    onSort () {
      this.resetPage()
      this.getReleases()
    },
    onTypeChange () {
      this.resetFilters()
      this.resetSearch()
      this.resetPage()
      this.getReleases()
      this.getFilters()
    },
    onResetSearchClick () {
      this.resetFilters()
      this.closeFilterPanels()
      this.resetSearch()
      this.getReleases()
    },
    onEditSave () {
      this.resetPage()
      this.getReleases()
    },
  },
}
</script>
