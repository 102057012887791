export const BLURAY_VIDEO_SOURCE_ID = 1;
export const BLURAY_3D_VIDEO_SOURCE_ID = 2;
export const UHD_BLURAY_VIDEO_SOURCE_ID = 11;
export const VIDEO_RESOLUTION_1080P_ID = 1;
export const VIDEO_RESOLUTION_2160P_ID = 2;
export const X264_VIDEO_FORMAT_ID = 1;
export const X265_VIDEO_FORMAT_ID = 4;
export const MP3_AUDIO_FORMAT_ID = 9;
export const AUDIO_CHANNEL_20_ID = 4;

export const movieDefaultValues = {
  name: '',
  language_id: 1,
  audio_format_id: 1,
  audio_channel_id: 1,
  audio_source_id: 0,
  video_format_id: 1,
  video_source_id: 1,
  video_resolution_id: 1,
  has_atmos: false,
  has_dtsx: false,
  has_dolby_vision: false,
  has_hdr: false,
  has_hdr_10_plus: false,
  has_hfr: false,
  hd: '14',
};
