export default {
  setSnack (state, snack) {
    state.snack = snack
  },
  setReleases (state, releases) {
    state.releases = releases
  },
  setPage (state, page) {
    state.page = page
  },
  resetPage (state) {
    state.page = 1
  },
  resetSearch (state) {
    state.sortBy = 'created_at'
    state.order = 'desc'
    state.page = 1
    state.q = ''
    state.selectedFilterValues = {}
  },
  resetFilters (state) {
    state.selectedFilterValues = {}
  },
  closeFilterPanels (state) {
    state.filterPanels = null
  },
  setFilterPanels (state, filterPanels) {
    state.filterPanels = filterPanels
  },
  setTotal (state, total) {
    state.total = total
  },
  setLastPage (state, lastPage) {
    state.lastPage = lastPage
  },
  setFrom (state, from) {
    state.from = from
  },
  setTo (state, to) {
    state.to = to
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setQuery (state, q) {
    state.q = q
  },
  setSortBy (state, sortBy) {
    state.sortBy = sortBy
  },
  setOrder (state, order) {
    state.order = order
  },
  setFilters (state, filters) {
    state.filters = filters
  },
  setSelectedFilterValue (state, { filterKey, value }) {
    state.selectedFilterValues = {
      ...state.selectedFilterValues,
      [filterKey]: value,
    }
  },
  setFiltersLoading (state, filtersLoading) {
    state.filtersLoading = filtersLoading
  },
  setSelectedTypeOption (state, selectedTypeOption) {
    state.selectedTypeOption = selectedTypeOption
  },
}
