import i18n from '@/i18n'

export const MOVIE_TYPE = 1
export const GAME_TYPE = 2
export const APPLICATION_TYPE = 3
export const SERIES_TYPE = 4

export const getEndpointFromType = (type) => {
  if (type === MOVIE_TYPE) {
    return 'movies'
  } else if (type === GAME_TYPE) {
    return 'games'
  } else if (type === APPLICATION_TYPE) {
    return 'applications'
  } else if (type === SERIES_TYPE) {
    return 'series'
  }
}

export const getNameFromType = (type) => {
  if (type === MOVIE_TYPE) {
    return i18n.t('types.movie')
  } else if (type === GAME_TYPE) {
    return i18n.t('types.game')
  } else if (type === APPLICATION_TYPE) {
    return i18n.t('types.application')
  } else if (type === SERIES_TYPE) {
    return i18n.t('types.series')
  }
}
