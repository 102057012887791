import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=40e084a4&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=40e084a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e084a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VPagination,VSkeletonLoader})
