<template>
  <v-chip
    small
    :color="color"
    class="mx-1"
  >
    {{ label }}
  </v-chip>
</template>

<script>
const typeToColorMap = {
  'neutral': '',
  'positive': 'green',
  'rather-positive': 'green',
  'rather-negative': 'orange',
  'negative': 'red',
}

export default {
  name: 'InfoChip',
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default () {
        return 'neutral'
      },
    },
  },
  computed: {
    color () {
      return typeToColorMap[this.type]
    },
  },
}
</script>
