<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle class="mb-1">
        {{ getTypeName(selectedTypeOption) }} | {{ formattedCreatedAt }}
      </v-list-item-subtitle>
      <v-list-item-title class="mb-1">
        {{ movie.name }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="d-flex flex-wrap"
        :class="$vuetify.breakpoint.xs ? 'flex-column align-start' : 'flex-row align-center'"
      >
        <div
          class="d-flex align-center flex-wrap"
        >
          <v-icon
            small
            class="mr-2"
          >
            $vuetify.icons.film
          </v-icon>
          {{ movie.video_source ? movie.video_source.name : '' }} ({{ movie.video_resolution ? movie.video_resolution.name : '' }} {{ movie.video_format ? movie.video_format.name : '' }})
          <InfoChip
            v-if="movie.has_dolby_vision"
            type="positive"
            :label="$t('components.movieListItem.dv')"
          />
          <InfoChip
            v-else-if="movie.has_hdr_10_plus"
            type="positive"
            :label="$t('components.movieListItem.hdr10plus')"
          />
          <InfoChip
            v-else-if="movie.has_hdr"
            type="rather-positive"
            :label="$t('components.movieListItem.hdr')"
          />
          <InfoChip
            v-if="movie.has_hfr"
            type="positive"
            :label="$t('components.movieListItem.hfr')"
          />
        </div>
        <div
          class="d-flex align-center flex-wrap"
          :class="$vuetify.breakpoint.xs ? '' : 'ml-4'"
        >
          <v-icon
            small
            class="mr-2"
          >
            $vuetify.icons.volumeUp
          </v-icon>
          {{ movie.language ? movie.language.name : '' }} ({{ movie.audio_source ? `${movie.audio_source.name} ` : '' }}{{ movie.audio_format ? movie.audio_format.name : '' }}{{ movie.audio_channel ? ` ${movie.audio_channel.name}` : '' }})
          <InfoChip
            v-if="movie.has_atmos"
            type="positive"
            :label="$t('components.movieListItem.atmos')"
          />
          <InfoChip
            v-if="movie.has_dtsx"
            type="positive"
            :label="$t('components.movieListItem.dtsx')"
          />
          <InfoChip
            v-if="movie.audio_format && movie.audio_format.is_lossless"
            type="positive"
            :label="$t('components.movieListItem.losslessAudio')"
          />
          <InfoChip
            v-if="movie.audio_source"
            type="negative"
            :label="$t('components.movieListItem.dubbed')"
          />
        </div>
        <v-spacer />
        <v-chip
          :class="$vuetify.breakpoint.xs ? '' : 'ml-2'"
        >
          <v-icon
            small
            class="mr-2"
          >
            $vuetify.icons.hdd
          </v-icon>
          {{ `#${movie.hd}` }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="flex-row align-center">
      <EditDialog
        :release="movie"
        @saved="onChanged"
        @deleted="onChanged"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoChip from '@/components/InfoChip';
import EditDialog from '@/components/List/EditDialog';
import { formatISODate } from '@/utils/date';
import { getNameFromType } from '@/utils/types';

export default {
  name: 'MovieItem',
  components: {
    EditDialog,
    InfoChip,
  },
  props: {
    movie: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('app', ['selectedTypeOption']),
    formattedCreatedAt () {
      return formatISODate(this.movie.created_at)
    },
  },
  methods: {
    onChanged () {
      this.$emit('changed')
    },
    getTypeName (type) {
      return getNameFromType(type)
    },
  },
}
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  overflow: initial;
  text-overflow: initial;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
