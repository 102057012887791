import http from '@/plugins/axios'
import { api } from '@/utils/axios'

export default {
  async check ({ commit }) {
    try {
      commit('setLoading', true)
      const res = await http.get(api('/user'))
      commit('setUser', res.data)
    } catch (e) {
      commit('setUser', null)
    } finally {
      commit('setLoading', false)
    }
  },
  async logout ({ commit }) {
    commit('setLoading', true)
    await http.post(api('/logout'))
    commit('setUser', null)
    // force browser reload on logout to reset local state
    window.location = '/login'
  },
  async login ({ commit }, data) {
    try {
      commit('setLoading', true)
      const res = await http.post(api('/login'), data)
      commit('setUser', res.data.user)
    } catch (e) {
      if (e.response && e.response.status === 403) {
        commit('app/setSnack', { message: e.response.data, color: 'error', translate: true }, { root: true })
      } else {
        commit('app/setSnack', { message: 'error.login', color: 'error', translate: true }, { root: true })
      }
      commit('setUser', null)
    } finally {
      commit('setLoading', false)
    }
  },
}
