import Vue from 'vue'

// Plugins
import vuetify from '@/plugins/vuetify';
import './plugins'

// Application imports
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
