<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle class="mb-1">
        {{ getTypeName(selectedTypeOption) }} | {{ formattedCreatedAt }}
      </v-list-item-subtitle>
      <v-list-item-title class="mb-1">
        {{ game.name }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="d-flex flex-wrap"
        :class="$vuetify.breakpoint.xs ? 'flex-column align-start' : 'flex-row align-center'"
      >
        <div
          class="d-flex align-center flex-wrap"
        >
          <v-icon
            small
            class="mr-2"
          >
            $vuetify.icons.volumeUp
          </v-icon>
          {{ game.language ? game.language.name : '' }}
        </div>
        <v-spacer />
        <v-chip
          :class="$vuetify.breakpoint.xs ? '' : 'ml-2'"
        >
          <v-icon
            small
            class="mr-2"
          >
            $vuetify.icons.hdd
          </v-icon>
          {{ `#${game.hd}` }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="flex-row align-center">
      <EditDialog
        :release="game"
        @saved="onChanged"
        @deleted="onChanged"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
import EditDialog from '@/components/List/EditDialog';
import { formatISODate } from '@/utils/date';
import { getNameFromType } from '@/utils/types';

export default {
  name: 'GameItem',
  components: {
    EditDialog,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('app', ['selectedTypeOption']),
    formattedCreatedAt () {
      return formatISODate(this.game.created_at)
    },
  },
  methods: {
    onChanged () {
      this.$emit('changed')
    },
    getTypeName (type) {
      return getNameFromType(type)
    },
  },
}
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  overflow: initial;
  text-overflow: initial;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
