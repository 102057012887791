import http from '@/plugins/axios'
import { api } from '@/utils/axios'
import { getEndpointFromType } from '@/utils/types';

export default {
  async getReleases ({ commit, state }) {
    const endpoint = getEndpointFromType(state.selectedTypeOption)
    commit('setLoading', true)
    try {
      const { q, page, itemsPerPage, sortBy, order, selectedFilterValues } = state
      const res = await http.get(
        api(`/${endpoint}`),
        {
          params: {
            q,
            page,
            itemsPerPage,
            sortBy,
            order,
            ...selectedFilterValues,
          },
        },
      )
      const { data, last_page, total, from, to } = res.data
      commit('setReleases', data)
      commit('setLastPage', last_page)
      commit('setTotal', total)
      commit('setFrom', from)
      commit('setTo', to)
    } catch (err) {
      commit('app/setSnack', { message: 'error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  async updateRelease ({ commit, state }, release) {
    const endpoint = getEndpointFromType(state.selectedTypeOption)
    try {
      await http.put(
        api(`/${endpoint}/${release.id}`),
        {
          ...release,
        },
      )
    } catch (err) {
      commit('app/setSnack', { message: 'error.updateRelease', color: 'error', translate: true }, { root: true })
    }
  },
  async createRelease ({ commit, state }, release) {
    const endpoint = getEndpointFromType(state.selectedTypeOption)
    try {
      await http.post(
        api(`/${endpoint}`),
        {
          ...release,
        },
      )
    } catch (err) {
      commit('app/setSnack', { message: 'error.createRelease', color: 'error', translate: true }, { root: true })
    }
  },
  async deleteRelease ({ commit, state }, release) {
    const endpoint = getEndpointFromType(state.selectedTypeOption)
    try {
      await http.delete(
        api(`/${endpoint}/${release.id}`),
      )
    } catch (err) {
      commit('app/setSnack', { message: 'error.deleteRelease', color: 'error', translate: true }, { root: true })
    }
  },
  async getFilters ({ commit, state }) {
    const endpoint = getEndpointFromType(state.selectedTypeOption)
    commit('setFiltersLoading', true)
    try {
      const res = await http.get(
        api(`/${endpoint}/filters`),
      )
      commit('setFilters', res.data)
    } catch (err) {
      commit('app/setSnack', { message: 'error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('setFiltersLoading', false)
    }
  },
}
