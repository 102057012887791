import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

// do authorization
router.beforeEach(async (to, from, next) => {
  // do not check authorization on login screen or if user is already set
  if (to.name === 'login' || store.state.auth.user) {
    next()
  } else {
    await store.dispatch('auth/check')

    if (store.state.auth.user) {
      next()
    } else {
      next({ name: 'login' })
    }
  }
})

export default router
