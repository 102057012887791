import i18n from '@/i18n'
import {
  APPLICATION_TYPE,
  GAME_TYPE,
  MOVIE_TYPE,
  SERIES_TYPE,
} from '@/utils/types';

export default {
  snack: null,
  releases: [],
  page: 1,
  sortBy: 'created_at',
  order: 'desc',
  typeOptions: [
    {
      text: i18n.t('components.listType.options.movies'),
      value: MOVIE_TYPE,
    },
    {
      text: i18n.t('components.listType.options.games'),
      value: GAME_TYPE,
    },
    {
      text: i18n.t('components.listType.options.applications'),
      value: APPLICATION_TYPE,
    },
    {
      text: i18n.t('components.listType.options.series'),
      value: SERIES_TYPE,
    },
  ],
  selectedTypeOption: MOVIE_TYPE,
  sortOptions: [
    {
      text: i18n.t('components.listSort.options.createdAtDesc'),
      value: {
        sortBy: 'created_at',
        order: 'desc',
      },
    },
    {
      text: i18n.t('components.listSort.options.createdAtAsc'),
      value: {
        sortBy: 'created_at',
        order: 'asc',
      },
    },
    {
      text: i18n.t('components.listSort.options.nameAsc'),
      value: {
        sortBy: 'name',
        order: 'asc',
      },
    },
    {
      text: i18n.t('components.listSort.options.nameDesc'),
      value: {
        sortBy: 'name',
        order: 'desc',
      },
    },
  ],
  itemsPerPage: 100,
  lastPage: 0,
  total: 0,
  from: 0,
  to: 0,
  loading: false,
  q: '',
  filtersLoading: false,
  filters: {},
  filterPanels: null,
  selectedFilterValues: {},
}
