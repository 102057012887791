import {
  APPLICATION_TYPE,
  GAME_TYPE,
  MOVIE_TYPE,
  SERIES_TYPE,
} from '@/utils/types';

export default {
  releases: state => state.releases,
  loading: state => state.loading,
  lastPage: state => state.lastPage,
  q: state => state.q,
  itemsPerPage: state => state.itemsPerPage,
  page: state => state.page,
  total: state => state.total,
  from: state => state.from,
  to: state => state.to,
  filters: state => state.filters,
  selectedFilterValues: state => state.selectedFilterValues,
  filtersLoading: state => state.filtersLoading,
  filterPanels: state => state.filterPanels,
  sortBy: state => state.sortBy,
  order: state => state.order,
  typeOptions: state => state.typeOptions,
  selectedTypeOption: state => state.selectedTypeOption,
  isMovieTypeSelected: state => state.selectedTypeOption === MOVIE_TYPE,
  isSeriesTypeSelected: state => state.selectedTypeOption === SERIES_TYPE,
  isGameTypeSelected: state => state.selectedTypeOption === GAME_TYPE,
  isApplicationTypeSelected: state => state.selectedTypeOption === APPLICATION_TYPE,
  sortOptions: state => state.sortOptions,
  selectedSortOption: state => {
    const { sortBy, order } = state
    return {
      sortBy,
      order,
    }
  },
}
