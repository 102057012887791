export const seriesDefaultValues = {
  name: '',
  language_id: 1,
  audio_format_id: 7,
  audio_channel_id: 1,
  audio_source_id: 0,
  video_format_id: 1,
  video_source_id: 4,
  video_resolution_id: 1,
  has_atmos: false,
  has_dtsx: false,
  has_dolby_vision: false,
  has_hdr: false,
  has_hdr_10_plus: false,
  has_hfr: false,
  hd: 'TV2',
};
