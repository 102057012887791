<template>
  <v-select
    :items="typeOptions"
    item-value="value"
    item-text="text"
    :label="$t('components.listType.label')"
    solo
    hide-details
    :value="selectedTypeOption"
    @input="onTypeClick"
  />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ListType',
  computed: {
    ...mapGetters('app', ['typeOptions', 'selectedTypeOption']),
  },
  methods: {
    ...mapMutations('app', ['setSelectedTypeOption']),
    onTypeClick (type) {
      if (this.selectedTypeOption !== type) {
        this.setSelectedTypeOption(type)
        this.$emit('type-change')
      }
    },
  },
}
</script>
