import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons/faVolumeUp'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faHdd } from '@fortawesome/free-regular-svg-icons/faHdd'
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun'
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally

library.add(
  faTimesCircle,
  faTimes,
  faUser,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faSquare,
  faCheckSquare,
  faKey,
  faSignOutAlt,
  faEdit,
  faFilm,
  faVolumeUp,
  faTrash,
  faPlus,
  faHdd,
  faSun,
  faMoon,
) // Include needed icons.

export const icons = {
  iconfont: 'faSvg',
  values: {
    // Icons used by Vuetify components
    prev: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-left'],
      },
    },
    next: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-right'],
      },
    },
    dropdown: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'caret-down'],
      },
    },
    expand: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-down'],
      },
    },
    checkboxOff: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'square'],
      },
    },
    checkboxOn: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'check-square'],
      },
    },
    delete: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times-circle'],
      },
    },
    clear: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times-circle'],
      },
    },
    // Icons used in application
    user: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'user'],
      },
    },
    key: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'key'],
      },
    },
    times: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times'],
      },
    },
    signOutAlt: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'sign-out-alt'],
      },
    },
    timesCircle: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times-circle'],
      },
    },
    edit: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'edit'],
      },
    },
    film: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'film'],
      },
    },
    volumeUp: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'volume-up'],
      },
    },
    trash: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'trash'],
      },
    },
    plus: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'plus'],
      },
    },
    hdd: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'hdd'],
      },
    },
    sun: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'sun'],
      },
    },
    moon: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'moon'],
      },
    },
  },
}
