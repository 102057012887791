import Vue from 'vue'

// Lib imports
import axios from 'axios'

const http = axios.create({
  withCredentials: true,
})

Vue.prototype.$http = http

export default http
