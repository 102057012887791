<template>
  <v-select
    :items="sortOptions"
    item-value="value"
    item-text="text"
    :label="$t('components.listSort.label')"
    solo
    hide-details
    :value="selectedSortOption"
    @input="onSortChange"
  />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ListSort',
  computed: {
    ...mapGetters('app', ['sortOptions', 'selectedSortOption']),
  },
  methods: {
    ...mapMutations('app', ['setSortBy', 'setOrder']),
    onSortChange (sort) {
      const { sortBy, order } = sort
      this.setSortBy(sortBy)
      this.setOrder(order)
      this.$emit('sort-change')
    },
  },
}
</script>
