<template>
  <div class="d-flex align-center">
    <v-icon
      small
      class="mr-3"
      :color="!$vuetify.theme.dark ? 'yellow darken-2': ''"
    >
      $vuetify.icon.sun
    </v-icon>
    <v-switch
      v-model="$vuetify.theme.dark"
      class="dark-mode-switch"
      color="white"
      hide-details
      inset
    />
    <v-icon
      small
      class="ml-1"
      :color="$vuetify.theme.dark ? 'yellow': ''"
    >
      $vuetify.icon.moon
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'DarkModeSwitch',
  watch: {
    '$vuetify.theme.dark'(newValue) {
      localStorage.setItem('releaselist-dark-mode', newValue);
    },
  },
  mounted() {
    const darkMode = localStorage.getItem('releaselist-dark-mode');
    if (darkMode) {
      this.$vuetify.theme.dark = darkMode === 'true';
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls__input {
    margin-right: 0;
  }
}
</style>
