<template>
  <v-list-item class="pa-0">
    <v-list-item-content class="pa-0">
      <v-skeleton-loader type="list-item" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItemSkeleton',
}
</script>
