<template>
  <v-expansion-panels
    v-model="panels"
    :disabled="filtersLoading"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('components.listFilters.header') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col
            v-for="filterKey in Object.keys(filters)"
            :key="filterKey"
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-if="filters[filterKey].type === 'select'"
              :items="filters[filterKey].options"
              :label="$t(`components.listFilters.${filterKey}`)"
              :clearable="true"
              item-value="id"
              item-text="name"
              solo
              hide-details
              :value="getValue(filterKey)"
              @input="(value) => setValue(filterKey, value)"
            />
            <v-checkbox
              v-if="filters[filterKey].type === 'checkbox'"
              :label="$t(`components.listFilters.${filterKey}`)"
              hide-details
              :value="getValue(filterKey)"
              @change="(value) => setValue(filterKey, value)"
            />
          </v-col>
        </v-row>
        <div class="text-center mt-5">
          <v-btn
            @click="onResetClick"
          >
            {{ $t('components.listFilters.reset') }}
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ListFilters',
  computed: {
    ...mapGetters('app', ['filters', 'filtersLoading', 'filterPanels', 'selectedFilterValues']),
    panels: {
      get () {
        return this.filterPanels
      },
      set (value) {
        this.setFilterPanels(value)
      }
    }
  },
  methods: {
    ...mapMutations('app', ['setSelectedFilterValue', 'resetFilters', 'setFilterPanels', 'closeFilterPanels']),
    getValue (filterKey) {
      return this.selectedFilterValues[filterKey]
    },
    setValue (filterKey, value) {
      this.setSelectedFilterValue({ filterKey, value })
      this.$emit('change')
    },
    onResetClick () {
      this.closeFilterPanels()
      this.resetFilters()
      this.$emit('reset')
    },
  },
}
</script>
