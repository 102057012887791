import { DateTime } from 'luxon'

export const formatISODate = (date, format = 'dd.MM.yyyy') => {
  return DateTime.fromISO(date).toFormat(format)
}

export const formatISODateForVuetify = (date) => {
  return formatISODate(date, 'yyyy-MM-dd')
}

export const getToday = () => {
  return DateTime.local().toISODate()
}
